// @ts-nocheck
import VueRouter from 'vue-router';
import About from './views/about/About.vue';
import * as Guard from '@/utils/navigation-guard';
import {
    userIsAdmin,
    userIsAtLeastCP,
    userIsAtLeastCPOrSupport,
    userIsAtLeastDP,
    userIsClient,
    userIsAtLeastExpert,
    userIsAtLeastCPOrExpert,
} from './app/helpers';

const Grid = () => import(/* webpackChunkName: "grid" */ './views/grid/Grid.vue');
const Opener = () => import(/* webpackChunkName: "opener" */ './views/time-modal/Opener.vue');
const VersionsCredits = () => import(/* webpackChunkName: "vcredits" */ './views/versions-credits/VersionsCredits.vue');
const Tree = () => import(/* webpackChunkName: "tree" */ './views/tree/Tree.vue');
// const Projects = () => import(/* webpackChunkName: "projects" */ './views/tree/Projects.vue');
const Projects2 = () => import(/* webpackChunkName: "projects2" */ './views/projects2/Projects2.vue');
const Logs = () => import(/* webpackChunkName: "log" */ './views/log/Logs.vue');
const LogTimeentries = () => import(/* webpackChunkName: "log" */ './views/log/timeentries/LogTimeentries.vue');
const LogIssues = () => import(/* webpackChunkName: "log" */ './views/log/issues/LogIssues.vue');
const BillingMarkers = () => import(/* webpackChunkName: "bmarkers" */ './views/billing-marker/BillingMarker.vue');
const Cram = () => import(/* webpackChunkName: "cram" */ './views/cram/Cram.vue');
const Dashboard = () => import(/* webpackChunkName: "dashboard" */ './views/dashboard/Dashboard.vue');
const Problems = () => import(/* webpackChunkName: "problems" */ './views/dashboard/problems/Problems.vue');
const Calendar = () => import(/* webpackChunkName: "calendar" */ './views/calendar/Calendar.vue');
const IssueOpener = () => import(/* webpackChunkName: "issueopener" */ './views/common/IssueOpener.vue');
const Groups = () => import(/* webpackChunkName: "groups" */ './views/groups/Groups.vue');
const KPI = () => import(/* webpackChunkName: "kpi" */ './views/kpi/KPI.vue');
const ProfilesChanger = () => import(/* webpackChunkName: "pchanger" */ './views/profiles-changer/ProfilesChanger.vue');
const MultiTasks = () => import(/* webpackChunkName: "mtasks" */ './views/multi-tasks/MultiTasks.vue');
const Skills = () => import(/* webpackChunkName: "skills" */ './views/skills/Skills.vue');
const TeamTime = () => import(/* webpackChunkName: "teamtime" */ './views/team-time/TeamTime.vue');
const Permissions = () => import(/* webpackChunkName: "permissions" */ './views/permissions/Permissions.vue');
const ProjectTime = () => import(/* webpackChunkName: "projecttime" */ './views/project-time/ProjectTime.vue');
const Staffing = () => import(/* webpackChunkName: "staffing" */ './views/staffing/Staffing.vue');

export const routes = [
    {
        path: '/',
        component: Dashboard,
        name: 'dashboard',
        meta: {
            forbids: userIsClient,
            andRedirects: { name: 'tree' },
            title: 'Dashboard',
            menu: true,
        },
    },
    {
        path: '/tree/:type?/:id?',
        component: Tree,
        name: 'tree',
        props: true,
        meta: {
            title: 'Arbre SPA',
            menu: true,
        },
    },
    {
        path: '/grid',
        component: Grid,
        name: 'grid',
        meta: {
            forbids: userIsClient,
            title: 'Planning Global',
            menu: true,
        },
    },
    {
        path: '/calendar/:timeEntryId?',
        component: Calendar,
        name: 'calendar',
        props: true,
        meta: {
            forbids: userIsClient,
            title: 'Planning Individuel',
            menu: true,
        },
    },
    {
        path: '/issue/:id',
        component: IssueOpener,
        name: 'issue',
        props: true,
        meta: {
            forbids: userIsClient,
            title: 'Tâche',
        },
    },
    {
        path: '/cram',
        component: Cram,
        name: 'cram',
        meta: {
            forbids: userIsClient,
            title: 'CRAM',
            menu: true,
        },
    },
    {
        path: '/about',
        component: About,
        name: 'about',
        meta: {
            forbids: userIsClient,
            title: 'Patch notes',
            menu: true,
        },
    },
    {
        path: '/time-entry/:resourceId/:issueId',
        component: Opener,
        name: 'timeentry',
        props: true,
        meta: {
            forbids: userIsClient,
            title: 'Saisie de temps',
        },
    },
    {
        path: '/team-time',
        component: TeamTime,
        name: 'team-time',
        meta: {
            forbids: userIsClient,
            title: 'Team Time',
            menu: true,
        },
    },
    {
        path: '/checks/:type',
        component: Problems,
        name: 'problems',
        props: true,
        meta: {
            forbids: userIsClient,
            title: 'Reports',
        },
    },
    {
        path: '/groups',
        component: Groups,
        name: 'groups',
        meta: {
            allows: userIsAtLeastCPOrExpert,
            title: 'Groups',
            menu: true,
        },
    },
    {
        path: '/log',
        component: Logs,
        name: 'histories',
        meta: {
            allows: userIsAtLeastCPOrSupport,
            title: 'Logs',
            menu: true,
        },
        children: [
            {
                path: 'issues',
                name: 'log-issues',
                component: LogIssues,
                meta: {
                    title: 'Logs - Tâches',
                },
            },
            {
                path: 'time-entries',
                name: 'log-timeentries',
                component: LogTimeentries,
                meta: {
                    title: 'Logs - Saisies',
                },
            },
        ],
    },
    {
        path: '/kpi',
        component: KPI,
        name: 'kpi',
        meta: {
            allows: userIsAtLeastCP,
            // allows: userIsAtLeastCPOrClient,
            title: 'KPIs',
            menu: true,
        },
    },
    {
        path: '/skills',
        component: Skills,
        name: 'skills',
        meta: {
            allows: userIsAtLeastExpert,
            title: 'Skills',
            menu: true,
        },
    },
    {
        path: '/versions-credits',
        component: VersionsCredits,
        name: 'versions-credits',
        meta: {
            allows: userIsAtLeastDP,
            title: 'Versions/Crédits',
            menu: true,
        },
    },
    {
        path: '/projects',
        component: Projects2,
        name: 'projects2',
        meta: {
            allows: userIsAtLeastDP,
            title: 'Projets',
            menu: true,
        },
    },
    {
        path: '/billing-markers',
        component: BillingMarkers,
        name: 'billing-markers',
        meta: {
            allows: userIsAtLeastDP,
            title: 'Jalons de facturation',
            menu: true,
        },
    },
    {
        path: '/project-time',
        component: ProjectTime,
        name: 'project-time',
        meta: {
            allows: userIsAtLeastDP,
            title: 'Project Time',
        },
    },
    {
        path: '/multi-tasks',
        component: MultiTasks,
        name: 'multitasks',
        meta: {
            allows: userIsAtLeastDP,
            title: 'Multi Tasker',
            menu: true,
        },
    },
    {
        path: '/profiles-changer',
        component: ProfilesChanger,
        name: 'profile-changer',
        meta: {
            allows: userIsAdmin,
            title: 'Changeur de profils',
            menu: true,
        },
    },
    {
        path: '/permissions',
        component: Permissions,
        name: 'permissions',
        meta: {
            allows: userIsAdmin,
            title: 'Permissions',
        },
    },
    {
        path: '/staffing',
        component: Staffing,
        name: 'staffing',
        meta: {
            allows: userIsAtLeastDP,
            title: 'Staffing',
            menu: true,
        },
    },
];

export const router = new VueRouter({
    routes,
});

router.beforeEach(Guard.globalBeforeEach);
