import * as RowTypes from '@/app/types/row-types';
import * as ChartTypes from '@/app/types/chart-types';
import * as Redmine from "@/config/redmine-constants";
import * as Config from "@/config/constants";
import { ISSUE_LINK, PROJECT_SETTINGS } from '@/config/api-routes';
import * as I from './types/interfaces';
import getEnv from '@/utils/env';


export function setRowCssClass(element, cssClasses) {
    element.classList.remove(
        RowTypes.RESOURCE_CLASS,
        RowTypes.PROJECT_CLASS,
        RowTypes.ISSUE_CLASS,
        RowTypes.FAKE_CLASS,
        RowTypes.PUBLIC_HOLIDAY_CLASS,
    );
    if (!cssClasses.length) return;
    element.classList.add(...cssClasses);
}


export function setItemCssClass(element, cssClasses) {
    element.classList.remove(
        ChartTypes.RESOURCE_HOURS_ITEM_CLASS,
        ChartTypes.ISSUE_ITEM_CLASS,
        ChartTypes.REAL_ITEM_CLASS,
        ChartTypes.LOCKED_ITEM_CLASS,
    );
    if (!cssClasses.length) return;
    element.classList.add(...cssClasses);
}


export function getCustomFieldValue(entity, customFieldId) {
    const matchingCustomFields = entity.custom_fields.filter(customField => customField.id === customFieldId);
    return matchingCustomFields.length > 0 ? matchingCustomFields[0].value : null;
}


export function getCustomFieldFromValue(customFieldId, value) {
    return { id: customFieldId, value: value };
}

function extrudeCustomFields(entity, cfMapping) {
    Object.keys(entity).forEach(property => {
        if (!cfMapping.hasOwnProperty(property)) return;
        if (!entity.hasOwnProperty('custom_fields')) entity.custom_fields = [];
        entity.custom_fields.push(getCustomFieldFromValue(cfMapping[property], entity[property]));
        delete entity[property];
    });
}

function extrudeExtraFields(entity, efMapping) {
    Object.keys(entity).forEach(property => {
        if (!efMapping.hasOwnProperty(property)) return;
        if (!entity.hasOwnProperty('extra_fields')) entity.extra_fields = {};
        entity.extra_fields[efMapping[property]] = entity[property];
        delete entity[property];
    });
}

export function extrudeCustomFieldsFromIssue(issue) {
    extrudeCustomFields(issue, Redmine.ISSUE_CUSTOM_FIELD_MAPPING);
}

export function extrudeCustomFieldsFromVersion(version) {
    extrudeCustomFields(version, Redmine.VERSION_CUSTOM_FIELD_MAPPING);
}

export function extrudeCustomFieldsFromTimeEntry(timeEntry: I.TimeEntryCrud) {
    extrudeCustomFields(timeEntry, Redmine.TIMEENTRY_CUSTOM_FIELD_MAPPING);
}

export function extrudeExtraFieldsFromTimeEntry(timeEntry: I.TimeEntryCrud) {
    extrudeExtraFields(timeEntry, Redmine.TIMEENTRY_EXTRA_FIELDS_MAPPING);
}


/*
 * Uses TIMEENTRY_INPUT_MAPPING to convert field names and values
 * This prepares input to be extruded for custom/extra/multiple fields
 */
function getTimeEntryInputFormatted(input: I.TimeEntryInput) {
    const timeEntry: any = {};
    if (input.timeEntries) {
        // ids: deletes/creates, id: updates
        if (input.timeEntries.length === 1) timeEntry.id = input.timeEntries[0].id;
        if (input.timeEntries.length > 1) timeEntry.ids = input.timeEntries.map(entry => entry.id);
        delete input.timeEntries;
    }
    // Auto submit Leaves
    if (input.project?.id === Redmine.ADMINISTRATIVE_PROJECT && !input.status) input.status = 1;
    // Initialize the timezone if start time is present
    if (input.startTime) input.tz = null;
    // Map config fields keeping not configured
    Object.keys(input).forEach(property => {
        const config = Redmine.INPUT_TIMEENTRY_MAPPING[property];
        if (!config) return timeEntry[property] = input[property];
        timeEntry[config.target] = config.handler ? config.handler(input) : input[property];
    });
    return timeEntry;
}


/*
 * Converts objects coming from components to the expected CRUD API format
 */
export function inputToTimeEntry(input: I.TimeEntryInput) {
    const timeEntry: I.TimeEntryCrud = getTimeEntryInputFormatted(input);
    extrudeCustomFieldsFromTimeEntry(timeEntry);
    extrudeExtraFieldsFromTimeEntry(timeEntry);
    return timeEntry;
}


export function timeEntryToInput(timeEntry: I.TimeEntry): I.TimeEntryInput {
    return {
        id: timeEntry.id,
        user: { id: timeEntry.user_id },
        activityId: timeEntry.activity_id,
        date: timeEntry.spent_on,
        futureTime: timeEntry.future_time,
        hours: timeEntry.hours,
        issue: { id: timeEntry.issue_id },
        project: { id: timeEntry.project_id },
        comment: timeEntry.comments,
        doneRatioBefore: timeEntry.done_ratio_before,
        doneRatioAfter: timeEntry.done_ratio_after,
        profileId: timeEntry.profile_id,
        status: timeEntry.status,
        startTime: timeEntry.start_time,
    };
}


/** Resource row time entries for a given date */
export function getResourceDateTimeEntries(timeEntries, resourceId, date): I.AggTimeEntryDate|null {
    if (timeEntries[resourceId]
        && timeEntries[resourceId].dates[date]
    ) {
        return timeEntries[resourceId].dates[date];
    }
    return null;
}


export function getResourceProjectTimeEntries(timeEntries, resourceId, projectId): I.AggTimeEntryProject|null {
    if (timeEntries[resourceId]
        && timeEntries[resourceId].projects[projectId]) {
        return timeEntries[resourceId].projects[projectId];
    }
    return null;
}


/** Issue row time entries indexed by date */
export function getResourceIssueTimeEntries(timeEntries, resourceId, projectId, issueId): I.AggTimeEntryIssues|null {
    const resourceProjectTimeEntries = getResourceProjectTimeEntries(timeEntries, resourceId, projectId);
    if (resourceProjectTimeEntries
        && resourceProjectTimeEntries.issues[issueId]) {
        return resourceProjectTimeEntries.issues[issueId];
    }
    return null;
}


export function getColorForHours(hours, adminHours) {
    const hGreen = 110; // admin
    const hBlue = 213;
    const lMin = 45;    // 8h
    const lMax = 100;
    const hRange = (hBlue - hGreen);
    const lRange = (lMax - lMin);
    adminHours = adminHours || 0;
    const hFactor = adminHours / hours;
    const lFactor = (hours > 8 ? 8 : hours) / 8;
    const h = hBlue - hRange * hFactor;
    const s = 57;
    const l = lMax - lRange * lFactor;
    return `hsl(${h}, ${s}%, ${l}%)`;
}
export function getColorsForHours(hours, adminHours, lMin = 70, lMax = 95) {
    const hGreen = 110; // admin
    const hBlue = 213;
    const hRange = (hBlue - hGreen);
    const lRange = (lMax - lMin);
    adminHours = adminHours || 0;
    const hFactor = adminHours / hours;
    const lFactor = (hours > 8 ? 8 : hours) / 8;
    const h = hBlue - hRange * hFactor;
    const s = 57;
    const l = lMax - lRange * lFactor;
    return `hsl(${h}, ${s}%, ${l}%)`;
}


// export function filterResourceBySkill(resource: Partial<User>, searchedSkill) {
//     const resourceSkills = resource.skills;
//     if (!resourceSkills) return false; // No skill
//     return !!(resourceSkills.split('\n').filter(skillLine => {
//         let skillLvl = parseInt(skillLine.split(':')[1]);
//         return !skillLvl ? false : skillLvl >= Config.SEARCH_MIN_SKILL_LVL; // Sufficient skill lvl
//     }).map(skillLine => skillLine.split(':')[0]).find(skill => {
//         return skill.toLowerCase().includes(searchedSkill.toLowerCase()); // First matching skill
//     }));
// }


export function filterResourceByResources(resource, selectedResources) {
    return selectedResources.map(resource => resource.id).includes(resource.id);
}


export function filterResourceByUserSkills(resource, usersSkills) {
    return usersSkills.some(userSkill =>
        userSkill.level >= Config.SEARCH_MIN_SKILL_LVL
        && resource.id === userSkill.userId
    );
}


export function filterResourceByProject(resource, project) {
    return project.member_ids.includes(resource.id.toString());
}


export function filterResourceByProjectTimeEntries(resource, timeEntries, project) {
    const resourceProjectTimeEntries = getResourceProjectTimeEntries(timeEntries, resource.id, project.id);
    return (resourceProjectTimeEntries || {}).hours > 0;
}


export function filterResourceBySales(resource, timeEntries) {
    if (!timeEntries.hasOwnProperty(resource.id)) return false;
    return timeEntries[resource.id].sales;
}


export function filterResourceByName(resource, search) {
    return normalizedContains(
        search,
        resource.firstname + " " + resource.lastname
    );
}


// TODO: Test & try to get rid of the store coupling
export function getResourceAvailableHours(dateTimeEntries) {
    const FULL_DAY = 8;
    if (!dateTimeEntries) return FULL_DAY;
    const bookedHours = dateTimeEntries.hours;
    return bookedHours < FULL_DAY ? FULL_DAY - bookedHours : 0;
}


export function capAvailableHoursForIssue(issue, available) {
    const estimated = issue ? issue.agg_estimated_hours : 0;
    const spent = issue ? issue.hours : 0;
    return (estimated > 0 ? Math.max(Math.min(available, estimated - spent), 0) : available);
}


// TODO: Test
export function userIsMemberOfProject(user, project) {
    return project.member_ids.includes(user.id.toString());
}


// TODO: Test
export function userIsCPDPForProject(user, project) {
    const isCP = project.cpa_ids.includes(user.id.toString());
    const isDP = project.dp_ids.includes(user.id.toString());
    return isCP || isDP;
}


const USER_PROFILE_CACHE: any = {};
export function getUserProfile(user: Partial<I.User>) {
    if (!USER_PROFILE_CACHE.hasOwnProperty(user.id)) {
        USER_PROFILE_CACHE[user.id] = user.profile;
    }
    return USER_PROFILE_CACHE[user.id];
}
export function userIsAdmin(user: Partial<I.User>) {
    if (!user) return false;
    return !!user.admin;
}
export function userIs(user: Partial<I.User>, profile) {
    if (userIsAdmin(user)) return false;
    return user.profile === profile;
}
export function userIsConsultant(user) {
    if (!getUserProfile(user)) return true;
    return userIs(user, Redmine.PROFILE_CONSULTANT)
        || userIs(user, Redmine.PROFILE_EXPERT);
}
function userIsCP(user) {
    return userIs(user, Redmine.PROFILE_PROJECT_MANAGER)
        || userIs(user, Redmine.PROFILE_PRESALES);
}
function userIsDP(user) {
    return userIs(user, Redmine.PROFILE_PROJECT_DIRECTOR);
}
function userIsExpert(user) {
    return userIs(user, Redmine.PROFILE_EXPERT);
}
function userIsSupport(user) {
    return userIs(user, Redmine.PROFILE_SUPPORT);
}
function userIsAccountant(user) {
    return user.id === 138;
}
export function userIsClient(user) {
    return userIs(user, Redmine.PROFILE_CLIENT);
}
export function userIsAtLeastExpert(user) {
    if (!user) return false;
    if (user.admin) return true;
    if (userIsDP(user)) return true;
    if (userIsExpert(user)) return true;
    return false;
}
export function userIsAtLeastCP(user: Partial<I.User>) {
    if (!user) return false;
    if (user.admin) return true;
    if (userIsDP(user)) return true;
    if (userIsCP(user)) return true;
    return false;
}
export function userIsAtLeastCPOrExpert(user: Partial<I.User>) {
    if (!user) return false;
    if (user.admin) return true;
    if (userIsCP(user)) return true;
    if (userIsAtLeastExpert(user)) return true;
    return false;
}
export function userIsAtLeastDP(user: Partial<I.User>) {
    if (!user) return false;
    if (user.admin) return true;
    if (userIsDP(user)) return true;
    return false;
}
export function userIsAtLeastCPOrClient(user: Partial<I.User>) {
    return userIsAtLeastCP(user) || userIsClient(user);
}
export function userIsAtLeastCPOrSupport(user: Partial<I.User>) {
    return userIsAtLeastCP(user) || userIsSupport(user);
}
export function userIsAtLeastAccountant(user: Partial<I.User>) {
    if (!user) return false;
    if (user.admin) return true;
    if (userIsAccountant(user)) return true;
    return false;
}
export function authorizeRule(meta, user) {
    if (!user) return false;
    if (meta) {
        if (meta.allows && !meta.allows(user)) return false;
        if (meta.forbids && meta.forbids(user)) return meta.andRedirects || false;
    }
    return true;
}


function projectHasMemberIds(project, resourceIds) {
    return resourceIds.length === project.member_ids.filter(id => {
        return resourceIds.includes(parseInt(id));
    }).length;
}
export function projectHasMembers(project, resources) {
    const resourceIds = resources.map(res => res.id);
    if (resourceIds.length === 0) return [];
    return projectHasMemberIds(project, resourceIds);
}
export function getAssignedProjects(projects, resourceIds) {
    if (resourceIds.length === 0) return [];
    return projects.filter(project => {
        return projectHasMemberIds(project, resourceIds);
    });
}


function removeDiacritics(text) {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}
function escapeRegExp(text) {
    return text.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}
export function normalizedContains(needle, haystack) {
    needle = removeDiacritics(needle);;
    needle = escapeRegExp(needle);;
    const regExp = new RegExp(needle, 'gi');
    return regExp.test(removeDiacritics(haystack));
}


export function hash(obj) {
    return JSON.stringify(obj);
}


export function getIssueLink(issueId) {
    return getEnv('VUE_APP_REDMINE_HOST') + ISSUE_LINK.replace('#issueId#', issueId);
}


export function getProjectLink(projectId) {
    return getEnv('VUE_APP_REDMINE_HOST') + PROJECT_SETTINGS.replace('#projectId#', projectId);
}


export function moneyFormat(amount) {
    return new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(amount);
}


export function getLockedUntil(lockedUntil) {
    return lockedUntil || Config.DEFAULT_LOCKED_UNTIL;
}


export function isLockedUntil(date, lockedUntil) {
    return date <= getLockedUntil(lockedUntil);
}


export function processXlsxResponse(fileName) {
    return (response) => {
        var blob = new Blob([response], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=utf-8;" });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
    };
}


export function throttle(callback, delay) {
    var last;
    var timer;
    return function () {
        var context = this;
        var now = +new Date();
        var args = arguments;
        if (last && now < last + delay) {
            clearTimeout(timer);
            timer = setTimeout(function () {
                last = now;
                callback.apply(context, args);
            }, delay);
        } else {
            last = now;
            callback.apply(context, args);
        }
    };
}
export function debounce(callback, delay){
    var timer;
    return function(){
        var args = arguments;
        var context = this;
        clearTimeout(timer);
        timer = setTimeout(function(){
            callback.apply(context, args);
        }, delay)
    }
}