import { fetchCube, upsert } from '@/utils/fetch';
import {
    TEAMTIME_USER_FOR_GROUP,
    TEAMTIME_PROJECT_FOR_USER,
    TEAMTIME_USER_FOR_USER,
    TEAMTIME_USER,
    TEAMTIME_PROJECT,
    STAFFING_TIME,
} from '@/config/cube-routes';
import { } from './mutation-types';


const actions = {
    userForGroup({ }, groupId) {
        const url = TEAMTIME_USER_FOR_GROUP.replace('#groupId#', groupId)
        return fetchCube(url).then(response => [response.data, response.leaves]);
    },
    userForUser({ }, userId) {
        const url = TEAMTIME_USER_FOR_USER.replace('#userId#', userId)
        return fetchCube(url).then(response => [response.data, response.leaves]);
    },
    projectForUser({ }, { userId, roles }) {
        const url = TEAMTIME_PROJECT_FOR_USER.replace('#userId#', userId)
        return fetchCube(url, { params: { roles } }).then(response => response.data);
    },
    upsertUser({}, data) {
        return upsert(data, TEAMTIME_USER);
    },
    upsertProject({}, data) {
        return upsert(data, TEAMTIME_PROJECT);
    },
    staffingTime({ }, { userIds, from, to, project }) {
        const url = (STAFFING_TIME + (project ? '/project' : ''))
            .replace('{userIds}', userIds.join(','))
            .replace('{from}', from)
            .replace('{to}', to)
        ;
        return fetchCube(url).then(response => response.data);
    },
};


const mutations = {
};


export default {
    namespaced: true,
    actions,
    mutations,
}
